
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PUBLIC_SHOP } from 'Consts/routes';
import {
    USER_OFFER_STATUS_NEW,
    USER_OFFER_STATUS_ACTIVE,
    USER_OFFER_STATUS_CLOSED,
    USER_OFFER_STATUS_BLOCKED,
    USER_OFFER_STATUS_TERMINATED,
    USER_OFFER_STATUS_TERMINATION,
} from 'Consts/userOffers';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import UserOffersList from 'Components/adept/userOffers/List';

export default class AdeptOrders extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };
    static defaultProps = {};

    defaultQuery = {
        tab: null,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="page-adept-orders"
                styles={require('./styles')}
            >
                <TabNavigation
                    location={location}
                    history={history}
                    headline="Zamówienia"
                    controls={[{
                        visible: true,
                        key: 'offerCalculator',
                        label: 'Kup nowy abonament',
                        style: 'gradient',
                        layout: 'wide',
                        onClick: () => history.push(
                            withVariables(
                                PUBLIC_SHOP.path
                            )
                        ),
                    }]}
                    tabs={[{
                        key: 'active',
                        label: 'Twoje aktywne zamówienia',
                        children: (
                            <UserOffersList
                                location={location}
                                history={history}
                                predefinedQuery={{
                                    statuses: [
                                        USER_OFFER_STATUS_NEW,
                                        USER_OFFER_STATUS_ACTIVE,
                                    ],
                                }}
                            />
                        ),
                    }, {
                        key: 'closed',
                        label: 'Zamknięte',
                        children: (
                            <UserOffersList
                                location={location}
                                history={history}
                                predefinedQuery={{
                                    statuses: [
                                        USER_OFFER_STATUS_CLOSED,
                                        USER_OFFER_STATUS_BLOCKED,
                                        USER_OFFER_STATUS_TERMINATED,
                                        USER_OFFER_STATUS_TERMINATION,
                                    ],
                                }}
                            />
                        ),
                    }]}
                />
            </StyledComponent>
        );
    }
}
